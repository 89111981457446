import React, { createContext, useState } from 'react';
import { BaseProps } from 'models/props';
import { WebhookModal } from 'components/modals/webook.modal';
import { AccountModal } from 'components/modals/account.modal';
import { PaylinksModal } from 'components/modals/paylinks.modal';
import { ProductlinkModal } from 'components/modals/productLink.modal';
import { EditAccountModal } from 'components/modals/edit-account.modal';
import { WithDrawalMethodModal } from 'components/modals/withdrawalMethod.modal';
import { CryptoMethodModal } from 'components/modals/cryptoMethod.modal';
import { MobileMethodModal } from 'components/modals/mobileMethod.modal';
import { WithdrawalModal } from 'components/modals/withdrawal.modal ';

type ModalContextProps = {
    title: string,
    props: any,
    showModal: (title: string, props: any, onClose?: (result: boolean) => void) => void,
    hideModal: (result?: boolean) => void,
    onHideModal: (title: string, onClose: () => void) => void,
}
export const ModalContext = createContext<ModalContextProps>({
    title: '',
    props: {},
    showModal: (title: string, props: any, onClose?: (result: boolean) => void) => { },
    hideModal: (result?: boolean) => { },
    onHideModal: (title: string, onClose: () => void) => { },
});

export const ModalProvider: React.FC<BaseProps> = ({ children }) => {
    const [title, setTitle] = useState('');
    const [props, setProps] = useState({});
    const [triggers, setTriggers] = useState<({ title: string, trigger: (() => void) })[]>([]);
    const showModal = (title: string, props: any, onClose?: (result: boolean) => void) => {
        setTitle(title);
        setProps({ ...props, onClose });
    };


    const onHideModal = (title: string, onClose: () => void) => {
        setTriggers([...triggers, { title, trigger: onClose }])
    };

    const hideModal = async (result?: boolean) => {
        setTitle('');
        setProps({});
        if (result) {
            await Promise.all(triggers.map((elt) => elt.title === title && elt.trigger()));
        }
    };


    const state = {
        title: title,
        props: props,
        showModal,
        hideModal,
        onHideModal
    };


    return <ModalContext.Provider value={state}>{children}</ModalContext.Provider>;
};

export const ModalRoot = () => {
    const ModalConsumer = ModalContext.Consumer;
    return (
        <ModalConsumer>
            {({ title, props }) => {
                const Component = modalsTable[title];
                return Component ? <Component {...props} /> : null
            }
            }
        </ModalConsumer>);
}

const modalsTable: any = {
    'WEBHOOK_MODAL': (props: any) => <WebhookModal {...props} />,
    'ACCOUNT_MODAL': (props: any) => <AccountModal  {...props} />,
    'PAYLINKS_MODAL': (props: any) => <PaylinksModal {...props} />,
    'PRODUCTLINK_MODAL': (props: any) => <ProductlinkModal {...props} />,
    'EDIT_ACCOUNT_MODAL': (props: any) => <EditAccountModal {...props} />,
    'WITHDRAWAL_METHOD_MODAL': (props: any) => <WithDrawalMethodModal {...props} />,
    'WITHDRAW_MODAL': (props: any) => <WithdrawalModal {...props} />,
    'CRYPTO_METHOD_MODAL': (props: any) => <CryptoMethodModal {...props} />,
    'MOBILE_METHOD_MODAL': (props: any) => <MobileMethodModal {...props} />,
}

