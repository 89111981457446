import { useState } from "react";
import { authInstance, baseInstance, statsInstance, paymentInstance, setParams } from 'api/utils/http';
import { AxiosInstance } from "axios";


const useQuery = (instance: AxiosInstance, url: string) => {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const post = async (postData: any, params?: any) => {
    try {
      setError(null);
      setIsLoading(true);
      params = setParams(params);
      const response = await instance.post<any>(url, postData, { ...params });
      setData(response.data);
      return response

    } catch (error: any) {
      setError(error?.response?.data?.message || error?.message);
      setData(null);
      return error

    } finally {
      setIsLoading(false);
    }

  };

  const get = async (params?: any) => {
    try {
      setError(null);
      setIsLoading(true);
      params = setParams(params);

      const response = await instance.get(url, { params });
      setData(response.data);
      return response

    } catch (error: any) {
      setError(error?.response?.data?.message || error?.message);
      setData(null);
      return error

    } finally {
      setIsLoading(false);
    }
  };

  return { data, error, isLoading, post, get };
};

export const useBaseQuery = (url: string) => useQuery(baseInstance, url);
export const useAuthQuery = (url: string) => useQuery(authInstance, url);
export const useStatsQuery = (url: string) => useQuery(statsInstance, url);
export const usePaymentQuery = (url: string) => useQuery(paymentInstance, url);


