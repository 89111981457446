import { Row, Col, Button, Watermark } from 'antd';

export const ErrorComponent = ({ error, resetErrorBoundary }: any) => {
    return (
        <div role="alert">
            <Watermark content="Ejara Merchant Panel">
                <Row justify={'space-around'} align={'middle'} className='h-full'>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} className='pl-60'>
                        <h1 className='text-larger mb-5'>Error Occurred</h1>
                        <p className='text-large'>{error.message}</p>
                        <Button className="primary mt-25" size="large" onClick={resetErrorBoundary}>Try again</Button>
                    </Col>
                    <Col xs={{ span: 0 }} lg={{ span: 12 }}>
                        <img src="/assets/error.svg" alt="" width={500} height={500} />
                    </Col>
                </Row>
            </Watermark>
        </div>
    )
}