import { Alert, Col, Form, Input, message } from "antd";
import { BaseModal } from "./base.modal";
import { useBaseQuery } from "hooks/query.hook";
import { apiRoutes } from "api/data/routes";
import { useState, useEffect } from "react";
import { AvatarSelector } from "components/avatars-selector";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { storeAccount } from "reducers/auth/actions-creator";
import { Account } from "models/user";
import { useTranslation } from "react-i18next";

export const EditAccountModal = ({ onClose, account }: any) => {
    const {
        error,
        isLoading,
        post,
    } = useBaseQuery(apiRoutes.ADMIN_SETTINGS);

    const { t } = useTranslation("login");

    const dispatch: Dispatch<any> = useDispatch();

    const [form] = Form.useForm();

    const [status, setStatus] = useState<any>('OPEN');


    const [closeStatus, setCloseStatus] = useState<any>('OPEN');
    const [avatar, setAvatar] = useState<any>('');


    const fields = [
        {
            name: 'name',
            value: account?.name
        }
    ]


    const setAccount = (account: Account) => {
        dispatch(storeAccount(
            { account }))
    }




    useEffect(() => {
        if (!error && closeStatus === 'CLOSE') {
            setAccount({ ...form.getFieldsValue(), avatar });
            message.success(t("accountUpdatedMessage"));
            setStatus('CLOSE');
            onClose && onClose(true);
        }
    }, [closeStatus])



    return (
        <BaseModal confirmLoading={isLoading} onOk={() => form.submit()} status={status}  okTitle={t("updateAccountBtnLabel")} title={t("updateAccountLabel")} onCancel={onClose} subTitle={t("updateccountSubLabel")}>
            <Form
                name="signup-form"
                form={form}
                fields={fields}
                requiredMark={false}
                onFinish={async (values) => {
                    await post({ ...values, avatar })
                    setCloseStatus('CLOSE')

                }}
                layout="vertical"
            >
                <Col className="gutter-row">
                    <Form.Item
                        name="name"
                        label={t("accountNameLabel")}
                        rules={[{ required: true, message: "" }]}
                    >
                        <Input size="large" />
                    </Form.Item>
                    <AvatarSelector setAvatar={(elt) => { setAvatar(elt) }} selected={account?.avatar} />
                    {error && <Alert message={error} type="error" className="text-error mt-10 " showIcon closable />}
                </Col>
            </Form>

        </BaseModal >
    );
}