import * as actionTypes from './action-type';
import { AuthAction, AuthenticationState } from './type';
const initialState: AuthenticationState = {
    error: null,
    user: null,
    payload: {}
}

const authReducer = (
    state: AuthenticationState = initialState,
    action: AuthAction
): AuthenticationState => {
    switch (action.type) {
        case actionTypes.LOGIN: {
            state = { ...state, ...action.state }
            return state;
        }
        case actionTypes.LOGOUT: {
            state = {
                error: null,
                user: null,
                payload: {}
            }
            return state;
        }
        case actionTypes.REFRESH_TOKEN: {
            state = {
                ...state,
                payload: action.state.payload
            }
            return state;
        }

        case actionTypes.STORE_ACCOUNT: {
            state = {
                ...state,
                account: action.state.account
            }
            return state;
        }
    }
    return state
}

export default authReducer