import { BaseModal } from "./base.modal";
import { useTranslation } from "react-i18next";
import { CryptoMethodIcon, MobileMethodIcon } from "icons/methods";
import { useModalWithoutTitle } from "hooks/modal.hook";
export const WithDrawalMethodModal = () => {

    const { t } = useTranslation('balance');

    const [openModal] = useModalWithoutTitle();

    const methods = [
        {
            label: t('methodTypes.crypto.label'),
            icon: <CryptoMethodIcon />,
            modal: () => openModal('CRYPTO_METHOD_MODAL'),
        },
        {
            label: t('methodTypes.mobile.label'),
            icon: <MobileMethodIcon />,
            modal: () => openModal('MOBILE_METHOD_MODAL'),
        }
    ]
    return (
        <BaseModal status="OPEN" title={t('createTitle')} subTitle={t('createSubtitle')} >
            <div className="method-container">
                {methods.map((method) => {
                    return (<div className="method-content" onClick={method.modal} >
                        {method.icon}
                        <h3>{method.label}</h3>
                    </div>)
                })}
            </div>
        </BaseModal >
    )
}