import { ModalContext } from "contexts/modal.context";
import { useContext } from "react";

export const useModal = (title: string) => {
    const { showModal, hideModal } = useContext(ModalContext);

    const showModalByHook = (props?: any, onClose?: (result: boolean) => void) => { showModal(title, props, onClose) };

    const hideModalByHook = () => hideModal();


    return [showModalByHook, hideModalByHook];
};

export const useModalWithoutTitle = () => {
    const { showModal, hideModal } = useContext(ModalContext);

    const showModalByHook = (title: string, props?: any, onClose?: (result: boolean) => void) => { showModal(title, props, onClose) };

    const hideModalByHook = () => hideModal();


    return [showModalByHook, hideModalByHook];
};

export const useOnHideModal = (title: string) => {
    const { onHideModal } = useContext(ModalContext);
    const onHideModalByHook = (onClose: () => void) => onHideModal(title, onClose);

    return [onHideModalByHook];

};