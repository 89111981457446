import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import App from "App";
TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// serviceWorkerRegistration.register();