import { Spin } from "antd";
import React from "react";

export const Loading = () => {
    return (
        <div className="loading-container">
            <Spin tip="loading" size="large">
            </Spin>
        </div>
    );
}