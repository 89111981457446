export const CryptoMethodIcon = () => {
    return (
        <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="80" height="80" rx="40" fill="#F6F6FE" />
            <path d="M28.833 28.3333L31.1663 30.6666M52.1663 28.3333L49.833 30.6666M52.1663 51.6666L49.833 49.3333M28.833 51.6666L31.1663 49.3333M28.833 44.6666H30.583M35.833 28.3333V30.0833M52.1663 35.3333H50.4163M45.1663 51.6666V49.9166M47.4997 44.6666H51.583M45.1663 28.3333V32.9999M28.833 35.3333H33.4997M35.833 51.6666V46.9999M39.8397 34.8266L35.3263 39.34C35.0953 39.571 34.9798 39.6865 34.9365 39.8197C34.8984 39.9368 34.8984 40.063 34.9365 40.1802C34.9798 40.3134 35.0953 40.4289 35.3263 40.6599L39.8397 45.1733C40.0707 45.4043 40.1862 45.5198 40.3194 45.5631C40.4366 45.6011 40.5628 45.6011 40.6799 45.5631C40.8131 45.5198 40.9286 45.4043 41.1596 45.1733L45.673 40.6599C45.904 40.4289 46.0196 40.3134 46.0628 40.1802C46.1009 40.063 46.1009 39.9368 46.0628 39.8197C46.0196 39.6865 45.9041 39.571 45.673 39.34L41.1596 34.8266C40.9286 34.5955 40.8131 34.48 40.6799 34.4368C40.5628 34.3987 40.4366 34.3987 40.3194 34.4368C40.1862 34.48 40.0707 34.5955 39.8397 34.8266Z" stroke="#505AE9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export const MobileMethodIcon = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="80" height="80" rx="40" fill="#FFF7E5" />
            <path d="M39.9997 46.4166H40.0113M35.5663 51.6666H44.433C45.7398 51.6666 46.3932 51.6666 46.8923 51.4123C47.3314 51.1886 47.6883 50.8316 47.912 50.3926C48.1663 49.8934 48.1663 49.24 48.1663 47.9333V32.0666C48.1663 30.7598 48.1663 30.1064 47.912 29.6073C47.6883 29.1682 47.3314 28.8113 46.8923 28.5876C46.3932 28.3333 45.7398 28.3333 44.433 28.3333H35.5663C34.2596 28.3333 33.6062 28.3333 33.107 28.5876C32.668 28.8113 32.311 29.1682 32.0873 29.6073C31.833 30.1064 31.833 30.7598 31.833 32.0666V47.9333C31.833 49.24 31.833 49.8934 32.0873 50.3926C32.311 50.8316 32.668 51.1886 33.107 51.4123C33.6062 51.6666 34.2596 51.6666 35.5663 51.6666ZM40.583 46.4166C40.583 46.7388 40.3218 46.9999 39.9997 46.9999C39.6775 46.9999 39.4163 46.7388 39.4163 46.4166C39.4163 46.0944 39.6775 45.8333 39.9997 45.8333C40.3218 45.8333 40.583 46.0944 40.583 46.4166Z" stroke="#FFB400" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export const BankMethodIcon = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="80" height="80" rx="40" fill="#E7FAED" />
            <path d="M29.5 50.5001H50.5M33 47.0001V37.6668M37.6667 47.0001V37.6668M42.3333 47.0001V37.6668M47 47.0001V37.6668M49.3333 34.1668L40.4947 28.6426C40.3152 28.5304 40.2255 28.4744 40.1293 28.4525C40.0442 28.4332 39.9558 28.4332 39.8707 28.4525C39.7745 28.4744 39.6848 28.5304 39.5053 28.6426L30.6667 34.1668H49.3333Z" stroke="#0CCA4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}