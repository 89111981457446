import React, { createContext, startTransition, useEffect, useState } from 'react';
import { BaseProps } from 'models/props';
import { shallowEqual, useSelector } from 'react-redux';
import { AuthenticationState } from 'reducers/auth/type';

export const AuthContext = createContext({
    isAuthenticated: false,
    setIsAuthenticated: () => { }
});

export const AuthProvider: React.FC<BaseProps> = ({ children }) => {
    const { user } = useSelector((state: AuthenticationState) => state, shallowEqual)
    const [isAuthenticated, setAuthenticated] = useState(!!user);

    useEffect(() => {
        setIsAuthenticated();
    }, [user]);

    const setIsAuthenticated = () => {
        startTransition(() => {
            setAuthenticated(!!user);
        });
    }

    return <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>{children}</AuthContext.Provider>;
};

