import { Alert, Form, Input, message } from "antd";
import { BaseModal } from "./base.modal";
import { useBaseQuery } from "hooks/query.hook";
import { apiRoutes } from "api/data/routes";
import { useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { CustomUpload } from "components/upload";
import { generateFormDataWithFiles } from "utils";
import { useTranslation } from "react-i18next";

export const ProductlinkModal = ({ onClose, data }: any) => {
    const {
        post,
        error: updateError
    } = useBaseQuery(`${apiRoutes.ADMIN_PRODUCT_URL}/${data.trackingCode}`);

    const {
        post: deletePost,
        error: deleteError,
    } = useBaseQuery(`${apiRoutes.ADMIN_PRODUCT_DELETE_IMAGES}/${data.trackingCode}`);


    const { t } = useTranslation('paylinks');

    const [form] = Form.useForm();

    const [status, setStatus] = useState<any>('OPEN');

    const [files, setFiles] = useState<any>([]);

    const [isLoading, setLoading] = useState<boolean>(false);

    const [deleteList, setDeleteList] = useState<string[]>([]);

    const fields = [
        {
            name: 'fiatAmount',
            value: data?.fiatAmount
        },
        {
            name: 'description',
            value: data?.description
        },
    ]

    const onSubmit = async (values: any) => {
        setLoading(true);
        if (deleteList.length > 0) {
            const indexes = deleteList.map((elt) => data?.imageUrls.findIndex((e: string) => e === elt));
            await deletePost({ imageIds: indexes });
        }

        if (files.length > 0) {
            await post(generateFormDataWithFiles(values, files), { headers: { "Content-Type": "multipart/form-data" } });
        }
        setLoading(false);
        if (!updateError && !deleteError) {
            message.success(t('updateProductMessage'));
            onClose && onClose(true);
            setStatus('CLOSE_TRIGGER');
        }
    }
    return (
        <BaseModal confirmLoading={isLoading} onOk={() => form.submit()} status={status} okTitle={t('updateBtn')} title={t('updateTitle')} subTitle={t('updateSubtitle')}>
            <Form
                className="paylink-form"
                name="signup-form"
                form={form}
                fields={fields}
                requiredMark={false}
                onFinish={onSubmit}
                layout="vertical"
            >

                <Form.Item
                    name="fiatAmount"
                    label={t('amountLabel')}
                    rules={[{ required: true, message: "" }]}
                >
                    <Input size="large" readOnly />
                </Form.Item>

                <Form.Item
                    name="description"
                    label={t('descriptionLabel')}
                    rules={[{ required: true, message: "" }]}
                >
                    <TextArea
                        rows={4}
                        placeholder={t('descriptionPlaceHolder')}
                        maxLength={100}
                        readOnly
                    />
                </Form.Item>

            </Form>
            <CustomUpload imageUrls={data?.imageUrls} onChange={(files) => {
                setFiles(files);
            }}
                onRemove={(toDelete) => {
                    setDeleteList(toDelete || []);
                }} />
            {updateError && <Alert message={updateError} type="error" className="text-error mt-10 " showIcon closable />}
            {deleteError && <Alert message={deleteError} type="error" className="text-error mt-10 " showIcon closable />}
        </BaseModal >
    );
}