import { Form, Input, Select } from "antd";
import { BaseModal } from "./base.modal";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { cryptos } from "data/stats";
export const WithdrawalModal = () => {
    const { t } = useTranslation('balance');
    const [form] = Form.useForm();

    const [status, setStatus] = useState<any>('OPEN');

    const [isLoading, setLoading] = useState<boolean>(false);


    const onSubmit = async (values: any) => {

    }
    return (
        <BaseModal title={t('withdraw.title')} subTitle={t('withdraw.subTitle')} confirmLoading={isLoading} onOk={() => form.submit()} status={status}>
            <Form
                className="paylink-form"
                name="signup-form"
                form={form}
                requiredMark={false}
                onFinish={onSubmit}
                layout="vertical"
            >

                <Form.Item
                    name="amount"
                    label={t('withdraw.fields.amount')}
                    rules={[{ required: true, message: "" }]}
                >
                    <Input size="large" readOnly type="number" />

                </Form.Item>

                <Form.Item
                    name="method"
                    label={t('withdraw.fields.method')}
                    rules={[{ required: true, message: "" }]}
                >
                    <Select
                        size="large"
                        options={cryptos}
                    />
                </Form.Item>

            </Form>
        </BaseModal >
    )
}