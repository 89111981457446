import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });

type CustomUploadProps = {
    imageUrls: string[],
    onChange: (files: (File | undefined)[]) => void
    onRemove: (deleteList?: string[]) => void
}
export const CustomUpload: React.FC<CustomUploadProps> = ({ onChange, onRemove, imageUrls }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [deleteList, setDeleteList] = useState<string[]>([]);
    const { t } = useTranslation('paylinks');

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };


    useEffect(() => {
        setFileList(imageUrls.map((elt, index) => {
            return {
                name: `image${index}`,
                uid: `-${index}`,
                url: elt
            }
        }
        ))
    }, [imageUrls])


    useEffect(() => {
        onChange(fileList.filter((elt) => elt?.originFileObj).map((elt) => elt?.originFileObj))
    }, [fileList])

    useEffect(() => {
        onRemove(deleteList);
    }, [deleteList])

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    }

    const handleRemove: UploadProps['onRemove'] = (file) => {
        if (file.url) {
            setDeleteList([...deleteList, file.url])
        }
        return true;
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>{t('upload')}</div>
        </div>
    );
    return (
        <>
            <Upload
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                className='upload-container'
                onRemove={handleRemove}
            >
                {fileList.length >= 8 ? null : uploadButton}
            </Upload >
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </>
    );
};