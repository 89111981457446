import i18n from '../i18n'
export const cryptos = [
    { value: "BTC", label: "BTC" },
    { value: "BTCW", label: "BTCW" },
    { value: "XTZ", label: "XTZ" },
    { value: "ETH", label: "ETH" },
    { value: "SOL", label: "SOL" },
    { value: "TRX", label: "TRX" },
    { value: "NEAR", label: "NEAR" },
    { value: "XLM", label: "XLM" },
    { value: "USDTXLM", label: "USDTXLM" },
    { value: "USDTSOL", label: "USDTSOL" },
    { value: "USDCOL", label: "USDCOL" },
    { value: "USDTTRX", label: "USDTTRX" },
    { value: "USDCTRX", label: "USDCTRX" },
    { value: "EURLXTZ", label: "EURLXTZ" },
    { value: "BNB", label: "BNB" },
    { value: "BSC", label: "BSC" },
    { value: "DOGE", label: "DOGE" },
]

export const mobiles = [
    { value: "OM", label: "Orange Money" },
    { value: "MOMO", label: "MTN Mobile Money" },
]

export const cryptoOptions = [
    {
        value: "ALL",
        label: i18n.t("allCrypto", { ns: 'dashboard' }),
    },
    {
        value: "BTC",
        label: "Bitcoin",
    },
    {
        value: "ETH",
        label: "Ethereum",
    },
    {
        value: "DOGE",
        label: "Doge",
    },
    {
        value: "USDT",
        label: "USDT",
    },
    {
        value: "USDC",
        label: "USDC",
    },
    {
        value: "SOL",
        label: "Solana",
    },
    {
        value: "TRX",
        label: "Tron",
    },
    {
        value: "MATIC",
        label: "Polygon",
    },
    {
        value: "BSC",
        label: "Binance SC",
    },
    {
        value: "NEAR",
        label: "Near",
    },
];

export const monthlabels = [
    "months.jan",
    "months.feb",
    "months.mar",
    "months.apr",
    "months.may",
    "months.jun",
    "months.jul",
    "months.aut",
    "months.sep",
    "months.oct",
    "months.nov",
    "months.dec",
];

export const daylabels = [
    "hours.00P",
    "hours.01A",
    "hours.02A",
    "hours.03A",
    "hours.04A",
    "hours.05A",
    "hours.06A",
    "hours.07A",
    "hours.08A",
    "hours.09A",
    "hours.11A",
    "hours.12P",
    "hours.01P",
    "hours.02P",
    "hours.03P",
    "hours.04P",
    "hours.05P",
    "hours.06P",
    "hours.07P",
    "hours.08P",
    "hours.09P",
    "hours.11P"
];

export const weeklabels = [
    "weeks.Mon",
    "weeks.Tue",
    "weeks.Wed",
    "weeks.Thu",
    "weeks.Fri",
    "weeks.Sat",
    "weeks.Sun"
];

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        title: {
            display: false,
            text: "Transactions",
        },
    },
    scales: {
        x: {
            grid: {
                display: false
            }
        },
        y: {
            beginAtZero: true,
            grid: {
                display: false
            }
        }
    }
};

export const frequencies = [
    'timeRange.daily',
    'timeRange.weekly',
    'timeRange.monthly',
    'timeRange.yearly'
]

export const paylinksStatus = [
    { label: 'status.all', value: '' },
    { label: 'status.initiated', value: 'INITIATED' },
    { label: 'status.failed', value: 'FAILED' },
    { label: 'status.pending', value: 'PENDING' },
    { label: 'status.incomplete', value: 'INCOMPLETE' },
    { label: 'status.partial', value: 'PARTIAL' },
    { label: 'status.completed', value: 'COMPLETED' },
]