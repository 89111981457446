export const routes = {
    HOME: '/',
    AUTH_LOGIN: "/auth/login",
    AUTH_SIGNUP: "/auth/signup",
    AUTH_RESET: "/auth/reset",
    DASHBOARD: "/dashboard",
    PAYLINKS: "/paylinks",
    PAYLINKS_ACTIVITY: "/paylinks/activities",
    TRANSACTIONS: "/transactions",
    BALANCE: "/balance",
    API: "/apikeys",
}