export const apiRoutes = {
    USER_ACCOUNT: "/admin/user/account",
    ADMIN_SETTINGS: "/admin/settings",
    AUTH_LOGIN: "/auth/login",
    USER_ACCOUNT_LIST: "/admin/user/accounts",
    AUTH_REFRESH_TOKEN: "/auth/reset-token",
    ADMIN_KEY: "/admin/key",
    ADMIN_WEBHOOK: "/admin/webhook",
    ADMIN_WEBHOOK_SECRET: "/admin/webhook/secret",
    ADMIN_PAYMENT_URL: "/admin/payment/url",
    ADMIN_PAYMENT_URL_LIST: "/admin/payment/urls",
    PAYMENT_TRANSACTION: "/admin/payment/transactions",
    ADMIN_BALANCE: "admin/balance",
    ADMIN_STATS: "/admin/api/stats",
    STAT_TRANSACTIONS: "/statistics/transaction",
    PAYMENT_URL: "/payment/url",
    ADMIN_PRODUCT_URL: "/admin/product/url",
    ADMIN_PRODUCT_DELETE_IMAGES: "/admin/product/url/removeimages",
    ADMIN_PRODUCT_URL_DISABLE: "/admin/product/url/disable",
    SET_LANGUAGE: "/admin/user/account/lang",
}