import { Alert, Col, Form, Input, message } from "antd";
import { BaseModal } from "./base.modal";
import { useBaseQuery } from "hooks/query.hook";
import { apiRoutes } from "api/data/routes";
import { useState, useEffect } from "react";
import { AvatarSelector } from "components/avatars-selector";
import { useTranslation } from "react-i18next";

export const AccountModal = ({ onClose }: any) => {
    const {
        error,
        isLoading,
        post,
    } = useBaseQuery(apiRoutes.USER_ACCOUNT);
    const { t } = useTranslation("login");


    const [form] = Form.useForm();

    const [status, setStatus] = useState<any>('OPEN');


    const [closeStatus, setCloseStatus] = useState<any>('OPEN');
    const [avatar, setAvatar] = useState<any>('');



    useEffect(() => {
        if (!error && closeStatus === 'CLOSE') {
            setStatus('CLOSE');
            onClose && onClose(true);
        }
    }, [closeStatus])



    return (
        <BaseModal confirmLoading={isLoading} onOk={() => form.submit()} status={status} okTitle={t("addAccountBtnLabel")} title={t("createAccountLabel")} onCancel={onClose} subTitle={t("createAccountSubLabel")}>

            <Form
                name="signup-form"
                form={form}
                requiredMark={false}
                onFinish={async (values) => {
                    await post({ ...values, avatar })
                    message.success(t("accountCreatedMessage"));
                    setCloseStatus('CLOSE')

                }}
                layout="vertical"
            >
                <Col className="gutter-row">
                    <Form.Item
                        name="name"
                        label={t("accountNameLabel")}
                        rules={[{ required: true, message: "" }]}
                    >
                        <Input size="large" />
                    </Form.Item>
                    <AvatarSelector setAvatar={(elt) => { setAvatar(elt) }} selected={avatar} />
                    {error && <Alert message={error} type="error" className="text-error mt-10 " showIcon closable />}
                </Col>
            </Form>

        </BaseModal >
    );
}