import React, { Suspense, lazy, useContext } from "react";
import { Routes, BrowserRouter, Route, Navigate } from "react-router-dom";
import { AuthContext } from "contexts/auth.context";
import { routes } from "data/routes";
import { Loading } from "components/ui/loading";

const Layout = lazy(() => import("containers/layout"));
const Allpaylinks = lazy(() => import("pages/payment/allPaylinks"));
const PaylinkActivityList = lazy(() => import("pages/payment/paylinkActivityList"));
const Transactions = lazy(() => import("pages/payment/transactions"));
const ApiKeys = lazy(() => import("pages/api/apiKeys"));
const Accounts = lazy(() => import("pages/auth/accounts"));
const Login = lazy(() => import("pages/auth/login"));
const ResetPassword = lazy(() => import("pages/auth/resetPassword"));
const Signup = lazy(() => import("pages/auth/signup"));
const Dashboard = lazy(() => import("pages/dashboard"));
const Balance = lazy(() => import("pages/balance"));


const Router = () => {
    const { isAuthenticated } = useContext(AuthContext);
    return (
        <BrowserRouter>
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route path={routes.HOME} element={isAuthenticated ? <Accounts /> : <Navigate to={routes.AUTH_LOGIN} />} />
                    <Route path={routes.HOME} element={isAuthenticated ? <Layout /> : <Navigate to={routes.AUTH_LOGIN} />} >
                        <Route path={routes.DASHBOARD} element={<Dashboard />} />
                        <Route
                            path={routes.PAYLINKS}
                            element={<Allpaylinks />}
                        />
                        <Route
                            path={`${routes.PAYLINKS_ACTIVITY}/:externalId`}
                            element={<PaylinkActivityList />}
                        />
                        <Route
                            path={routes.TRANSACTIONS}
                            element={<Transactions />}
                        />
                        <Route
                            path={routes.BALANCE}
                            element={<Balance />}
                        />
                        <Route path={routes.API} element={<ApiKeys />} />
                    </Route>
                    <Route path={routes.AUTH_LOGIN} element={<Login />} />
                    <Route path={routes.AUTH_SIGNUP} element={<Signup />} />
                    <Route path={routes.AUTH_RESET} element={<ResetPassword />} />
                </Routes>
            </Suspense>
        </BrowserRouter>);
};

export default Router;
