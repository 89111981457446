import { Form, Input } from "antd";
import { BaseModal } from "./base.modal";
import { useBaseQuery } from "hooks/query.hook";
import { apiRoutes } from "api/data/routes";
import { useState } from "react";

export const WebhookModal = ({ onCancel }: any) => {

    const [form] = Form.useForm();
    const {
        data: webHookData,
        error: webHookError,
        isLoading: webHookLoading,
        post: createWebHook,
    } = useBaseQuery(apiRoutes.ADMIN_WEBHOOK);
    const [status, setStatus] = useState<any>('OPEN');
    
    return (
        <BaseModal onOk={form.submit} confirmLoading={webHookLoading} status={status} okTitle="Add webhook" title="Add a webhook" subTitle="Enter amount, description and payer's email below." >
            <Form
                name="webhook-form"
                form={form}
                requiredMark={false}
                onFinish={(values) => {
                    createWebHook(values).then(
                        (val) => {
                            setStatus('CLOSE');
                            onCancel();
                        }
                    );
                }}
                layout="vertical"
            >
                <Form.Item
                    name="webHookUrl"
                    rules={[{ required: true, message: "" }]}
                >
                    <Input size="large" />
                </Form.Item>
                {webHookError && (
                    <Form.Item>
                        <h4 style={{ color: "red", textAlign: "center" }}>
                            {webHookError}
                        </h4>
                    </Form.Item>
                )}
            </Form>
        </BaseModal >)
}