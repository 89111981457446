export const ArrowRight = ({ className }: any) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M9.62004 12.5467C9.49337 12.5467 9.3667 12.5 9.2667 12.4C9.07337 12.2067 9.07337 11.8867 9.2667 11.6934L12.96 8.00002L9.2667 4.30668C9.07337 4.11335 9.07337 3.79335 9.2667 3.60002C9.46004 3.40668 9.78004 3.40668 9.97337 3.60002L14.02 7.64668C14.2134 7.84002 14.2134 8.16002 14.02 8.35335L9.97337 12.4C9.87337 12.5 9.7467 12.5467 9.62004 12.5467Z" fill="currentColor" />
            <path d="M13.5534 8.5H2.33337C2.06004 8.5 1.83337 8.27333 1.83337 8C1.83337 7.72667 2.06004 7.5 2.33337 7.5H13.5534C13.8267 7.5 14.0534 7.72667 14.0534 8C14.0534 8.27333 13.8267 8.5 13.5534 8.5Z" fill="currentColor" />
        </svg>

    )
}


export const ArrowDown = ({ className }: any) => {
    return (
        <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M6.00001 8.40001C5.65001 8.40001 5.30001 8.26501 5.03501 8.00001L1.77501 4.74001C1.63001 4.59501 1.63001 4.35501 1.77501 4.21001C1.92001 4.06501 2.16001 4.06501 2.30501 4.21001L5.56501 7.47001C5.80501 7.71001 6.19501 7.71001 6.43501 7.47001L9.69501 4.21001C9.84001 4.06501 10.08 4.06501 10.225 4.21001C10.37 4.35501 10.37 4.59501 10.225 4.74001L6.96501 8.00001C6.70001 8.26501 6.35001 8.40001 6.00001 8.40001Z" fill="currentColor" />
        </svg>

    )
}

export const SearchIcon = ({ className }: any) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M7.66659 14.5C3.89992 14.5 0.833252 11.4333 0.833252 7.66667C0.833252 3.9 3.89992 0.833334 7.66659 0.833334C11.4333 0.833334 14.4999 3.9 14.4999 7.66667C14.4999 11.4333 11.4333 14.5 7.66659 14.5ZM7.66659 1.83333C4.44659 1.83333 1.83325 4.45333 1.83325 7.66667C1.83325 10.88 4.44659 13.5 7.66659 13.5C10.8866 13.5 13.4999 10.88 13.4999 7.66667C13.4999 4.45333 10.8866 1.83333 7.66659 1.83333Z" fill="#505AE9" />
            <path d="M14.6666 15.1667C14.54 15.1667 14.4133 15.12 14.3133 15.02L12.98 13.6867C12.7866 13.4933 12.7866 13.1733 12.98 12.98C13.1733 12.7867 13.4933 12.7867 13.6866 12.98L15.02 14.3133C15.2133 14.5067 15.2133 14.8267 15.02 15.02C14.92 15.12 14.7933 15.1667 14.6666 15.1667Z" fill="#505AE9" />
        </svg>

    )
}


export const SelectArrowIcon = ({ className }: any) => {
    return (
        <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.62643 0C10.0457 0 10.2788 0.484966 10.0169 0.812347L5.72385 6.17862C5.52369 6.42883 5.14314 6.42883 4.94298 6.17862L0.649959 0.812347C0.388053 0.484965 0.62114 0 1.04039 0L9.62643 0Z" fill="#9FA1C0" />
        </svg>


    )
}

export const NoticeIcon = ({ className }: any) => {
    return (
        <svg width="42" height="43" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <circle cx="16" cy="16.5" r="16" fill="#3D2B00" />
            <path d="M15.8252 22.5315C19.2527 22.5315 22.0313 19.753 22.0313 16.3255C22.0313 12.8979 19.2527 10.1194 15.8252 10.1194C12.3977 10.1194 9.61914 12.8979 9.61914 16.3255C9.61914 19.753 12.3977 22.5315 15.8252 22.5315Z" fill="#FFEB69" />
            <path d="M15.8248 17.4115C16.0793 17.4115 16.2903 17.2005 16.2903 16.9461V13.843C16.2903 13.5886 16.0793 13.3776 15.8248 13.3776C15.5704 13.3776 15.3594 13.5886 15.3594 13.843V16.9461C15.3594 17.2005 15.5704 17.4115 15.8248 17.4115Z" fill="#3D2B00" />
            <path d="M16.3957 18.572C16.3646 18.4976 16.3212 18.4293 16.2653 18.3672C16.2033 18.3114 16.135 18.268 16.0605 18.2369C15.9116 18.1749 15.7378 18.1749 15.5889 18.2369C15.5144 18.268 15.4461 18.3114 15.3841 18.3672C15.3282 18.4293 15.2848 18.4976 15.2537 18.572C15.2227 18.6465 15.2041 18.7272 15.2041 18.8079C15.2041 18.8886 15.2227 18.9692 15.2537 19.0437C15.2848 19.1244 15.3282 19.1864 15.3841 19.2485C15.4461 19.3044 15.5144 19.3478 15.5889 19.3788C15.6633 19.4099 15.744 19.4285 15.8247 19.4285C15.9054 19.4285 15.9861 19.4099 16.0605 19.3788C16.135 19.3478 16.2033 19.3044 16.2653 19.2485C16.3212 19.1864 16.3646 19.1244 16.3957 19.0437C16.4267 18.9692 16.4453 18.8886 16.4453 18.8079C16.4453 18.7272 16.4267 18.6465 16.3957 18.572Z" fill="#3D2B00" />
        </svg>

    )
}

export const MenuButtonIcon = ({ className }: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={className}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
    )
}

export const LogoutIcon = ({ className }: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className={className}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75" />
        </svg>
    )
}

export const SwitchAccountIcon = ({ className }: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className={className}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
        </svg>
    )
}

export const ListIcon = ({ className }: any) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className={className}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
    </svg>
    )

}

export const BackIcon = ({ className }: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="20" height="20" strokeWidth={1} stroke="currentColor" className={className}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
        </svg>
    )
}


export const CloseIcon = ({ className }: any) => {
    return (
        <svg width="30" height="30" viewBox="0 0 40 40" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="20" fill="#EEEFFD" />
            <path d="M14.1667 14.1667L25.8334 25.8334M14.1668 25.8334L20.0001 20L25.8334 14.1667" stroke="#505AE9" strokeWidth="1.5" strokeLinecap="round" />
        </svg>

    )
}

export const EditIcon = ({ className }: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="23" height="23" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
        </svg>

    )
}

export const UploadIcon = ({ className }: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="25" height="25" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
        </svg>

    )
}

export const EditUserIcon = ({ className }: any) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
    </svg>
    )
}

