import { apiRoutes } from "api/data/routes";
import axios, { HttpStatusCode, InternalAxiosRequestConfig } from "axios";
import moment from "moment";
import { store } from "reducers/auth/store";
import * as actionTypes from "reducers/auth/action-type"


const handleRequestInterceptor = async (config: InternalAxiosRequestConfig<any>) => {
  const { payload, account } = store.getState();
  if (payload) {
    const { token, expires_in, refresh_token, refresh_token_expires_in } = payload;
    const currentDate = moment().valueOf();

    if (expires_in && currentDate > expires_in) {
      const response = await authInstance.post(`${apiRoutes.AUTH_REFRESH_TOKEN}`, { refreshToken: refresh_token });
      store.dispatch({ state: { payload: { expires_in, refresh_token, refresh_token_expires_in, token: response.data.data.token } }, type: actionTypes.REFRESH_TOKEN });
    }

    if (refresh_token_expires_in && currentDate > refresh_token_expires_in) {
      store.dispatch({ state: { payload: {} }, type: actionTypes.LOGOUT });
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

  }

  if (account) {
    const { merchantId } = account;
    config.headers['merchant-id'] = merchantId;
  }
  return config;
}

const handleResponseInterceptor = async (error: any) => {
  const originalRequest = error?.config;
  // if (originalRequest && error.response.status === HttpStatusCode.Unauthorized && !originalRequest._retry) {
  //   store.dispatch({ state: { payload: {} }, type: actionTypes.LOGOUT });
  // }
  return Promise.reject(error);
}


const baseInstance = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    "api-key": process.env.REACT_APP_API_KEY,
    "client-id": process.env.REACT_APP_CLIENT_ID,
  },
});

baseInstance.interceptors.request.use(
  handleRequestInterceptor,
  (error) => {
    return Promise.reject(error);
  }
)
baseInstance.interceptors.response.use((response) => {
  return response
}, handleResponseInterceptor);



const authInstance = axios.create({
  baseURL: `${process.env.REACT_APP_AUTH_URL}api/v1`,
  headers: {
    "Content-Type": "application/json",
    "api-key": process.env.REACT_APP_API_KEY,
    "client-id": process.env.REACT_APP_CLIENT_ID,
  },
});


const statsInstance = axios.create({
  baseURL: `${process.env.REACT_APP_STATS_BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    "api-key": process.env.REACT_APP_API_KEY,
    "client-id": process.env.REACT_APP_CLIENT_ID,
  },
});

statsInstance.interceptors.request.use(
  handleRequestInterceptor,
  (error) => {
    return Promise.reject(error);
  }
)
statsInstance.interceptors.response.use((response) => {
  return response
}, handleResponseInterceptor);



const paymentInstance = axios.create({
  baseURL: `${process.env.REACT_APP_PAYMENT_BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    "api-key": process.env.REACT_APP_API_KEY,
    "client-id": process.env.REACT_APP_CLIENT_ID,
  },
});

paymentInstance.interceptors.request.use(
  handleRequestInterceptor,
  (error) => {
    return Promise.reject(error);
  }
);

paymentInstance.interceptors.response.use((response) => {
  return response
}, handleResponseInterceptor);


const setParams = (params: any) => {
  for (const key in params) {
    if (!params[key]) { delete params[key] }
  }

  return params;
}
export { authInstance, baseInstance, statsInstance, paymentInstance, setParams };
