import * as actionTypes from "./action-type"
import { AuthAction, AuthenticationState, DispatchType } from "./type"

export function login(state: AuthenticationState) {
    const action: AuthAction = {
        type: actionTypes.LOGIN,
        state,
    }
    return dispatch(action)
}


export function logout() {
    const action: AuthAction = {
        type: actionTypes.LOGOUT,
        state: {},
    }
    return dispatch(action)
}


export function refresToken(state: AuthenticationState) {
    const action: AuthAction = {
        type: actionTypes.LOGOUT,
        state,
    }
    return dispatch(action)
}

export function dispatch(action: AuthAction) {
    return (dispatch: DispatchType) => {
        dispatch(action)
    }
}

export function storeAccount(state: AuthenticationState) {
    const action: AuthAction = {
        type: actionTypes.STORE_ACCOUNT,
        state,
    }
    return dispatch(action)
}