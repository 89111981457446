import { Button, Modal } from "antd";
import { ModalContext } from "contexts/modal.context";
import { CloseIcon } from "icons/arrows";
import React, { useContext, useEffect, useState } from "react";
type BaseModalProps = {
    children: any;
    title: string;
    subTitle?: string;
    status?: 'OPEN' | 'CLOSE' | 'CLOSE_TRIGGER';
    onCancel?: any;
    onOk?: any;
    okTitle?: string;
    confirmLoading?: boolean
}
export const BaseModal: React.FC<BaseModalProps> = ({ children, title, onCancel, onOk, okTitle, subTitle, confirmLoading, status }) => {
    const { hideModal } = useContext(ModalContext);

    const [open, setOpen] = useState(true);

    useEffect(() => {
        setOpen(status === 'OPEN')
        if (status === 'CLOSE') {
            hideModal();
        }

        if (status === 'CLOSE_TRIGGER') {
            hideModal(true);
        }
    }, [status])
    const handleCancel = () => {
        onCancel && onCancel();
        setOpen(false)
    }

    return (
        <Modal
            open={open}
            onCancel={handleCancel}
            afterClose={hideModal}
            footer={[]}

            closeIcon={<CloseIcon />}
        >

            <div className="modal">
                <div className="modal-header">
                    <h2 className="text-dark">{title}</h2>
                    <h4 className="text-gray">{subTitle}</h4>
                </div>
                {children}
                {onOk && <Button
                    onClick={onOk}
                    loading={confirmLoading}
                    className="btn primary mt-10"
                    block
                    size="large">
                    {okTitle || 'Submit'}
                </Button>}
            </div>

        </Modal>

    )
}