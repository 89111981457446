import { Form, Input, Select } from "antd";
import { BaseModal } from "./base.modal";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { cryptos } from "data/stats";
export const CryptoMethodModal = () => {
    const { t } = useTranslation('balance');
    const [form] = Form.useForm();

    const [status, setStatus] = useState<any>('OPEN');

    const [isLoading, setLoading] = useState<boolean>(false);


    const onSubmit = async (values: any) => {

    }
    return (
        <BaseModal title={t('createTitle')} subTitle={t('createSecondSubtitle')} confirmLoading={isLoading} onOk={() => form.submit()} status={status} okTitle={t('saveMethod')}>
            <Form
                className="paylink-form"
                name="signup-form"
                form={form}
                requiredMark={false}
                onFinish={onSubmit}
                layout="vertical"
            >

                <Form.Item
                    name="crypto"
                    label={t('methodTypes.crypto.fields.crypto')}
                    rules={[{ required: true, message: "" }]}
                >
                    <Select
                        size="large"
                        options={cryptos}
                    />
                </Form.Item>

                <Form.Item
                    name="address"
                    label={t('methodTypes.crypto.fields.address')}
                    rules={[{ required: true, message: "" }]}
                >
                    <Input size="large" readOnly />

                </Form.Item>

            </Form>
        </BaseModal >
    )
}