import { daylabels, monthlabels, weeklabels } from "data/stats";
import moment from "moment";
import i18n from "../i18n";
import { message } from 'antd';
export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getChartData = (type: 'Daily' | 'Weekly' | 'Monthly' | 'Yearly', dataMapping: any, t: any) => {
    const labelMapping = {
        Daily: daylabels,
        Weekly: weeklabels,
        Monthly: monthlabels,
        Yearly: getLastYears(),
    }

    return {
        labels: labelMapping[type].map(elt => t(elt)),
        datasets: [{
            label: 'Transactions',
            data: dataMapping ? dataMapping[type] : [],
            backgroundColor: "#505AE9",
            hoverBackgroundColor: "#505AE9",
            borderWidth: 0,
            borderRadius: 40,
            barPercentage: 0.5,
        }]
    };
};


export const getLastYears = () => {
    const year = +moment().format('YYYY');
    const years = [];
    for (let i = 0; i < 10; i++) {
        years.push((year - i).toString());
    }
    return years;
};

export const copyToclipBoard = (data: string | number) => {
    if (!data) { return }
    navigator.clipboard.writeText(data.toString());
    message.info(i18n.t('copied'))

}

export const generateFormDataWithFiles = (data: any, files: File[]) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
    })

    files.forEach((file) => {
        formData.append('files', file, file.name);
    });

    return formData;
}

export const currency = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'XAF',
  });
  

