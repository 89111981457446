export const avatars = [
    '01891804d18a5099dd03141574be67c3-sticker 1',
    '049d962cf8eba46d91df5679ce3cdf70-sticker 1',
    '107',
    '110',
    '1b461695fe4dd49815ffda8466b46caf-sticker 1',
    '23f743554c02ace58164931641b4761d-sticker 1',
    '45',
    '4eab3e01f8efe5931a566cabd3e13dc3-sticker 1',
    '4efb65b0d309c3bd527c4b7357ac1356-sticker 1',
    '5a446554d6eb3fef9a28c3133b1083dc-sticker 1',
    '5bc8b944f45d2d9683327da1b60d4d6e-sticker 1',
    '5bcf0418cddde6233b461ab81b5f3e4b-sticker 1',
    '69041d17aad7c61f9ac9ee84d7675976-sticker 1',
    '7377bd48411c20a8187241c4309762e6-sticker 1',
    '75d4f6f3e86aff346b7316ffde7c0508-sticker 1',
    '76a3aec9d507d4818afa775f239fe8e2-sticker 1',
    '9f60956335428f5f4b3d367f5c01b68a-sticker 1',
    'a67c1b6d9fbd8ae6a83fa8eae77f4489-sticker 1',
    'a6cf2879189ef4be9fd3ecef6f6ab109-sticker 1',
    'acc15e38fcf150ebb3121da1408e6a86-sticker 1',
    'b3ebaa2961e10361368a89b733b50174-sticker 1',
    'c12cff8c149eaa9992e49c8cfaa77a3f-sticker 1',
    'c5e76a15b5ea8583ccec8f2f090d1e17-sticker 1',
    'dde1ea1747b1b96b59801d6915b579c8-sticker 1',
    'e0ae314511e111e13c3be8b64df4f86b-sticker 1',
    'e59f44e3ff68bf71eee39fe20b77d4aa-sticker 1',
    'ed99969fde28e0684279a9a50f237ca1-sticker 1',
    'f138c042d73089bafdf4126f4051f25c-sticker 1',
    'f82775abb8f97d077c3700584fdb4977-sticker 1',
    'Female Memojis',
    'Male Memojis'
]

