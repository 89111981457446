import { Col, Row, Image } from "antd";
import { avatars } from "data/avatars";
import React, { useState, useEffect } from "react";
type AvatarSelectorProps = {
    selected?: string,
    setAvatar: (avatar: string) => void
}
export const AvatarSelector: React.FC<AvatarSelectorProps> = ({ selected, setAvatar }) => {
    const [avatar, selectAvatar] = useState<string>(selected || '');
    const [modify, setModify] = useState<boolean>(false);

    useEffect(() => {
        setAvatar(avatar);
    }, [avatar])

    // if (!modify) {
    //     return (
    //         <Row className="avatar-text">
    //             <Col className="gutter-row">
    //                 <h3 className="text-gray">{avatar ? 'Selected avatar' : "You don't have an avatar"}</h3>
    //             </Col>
    //             {avatar && <Col className="gutter-row ml-5">
    //                 <Image src={`avatars/${avatar}.svg`} alt={avatar} height={100} preview={false} width={100} />
    //             </Col>}
    //             <Col className="gutter-row ml-5">
    //                 <h3 className="text-primary pointer" onClick={() => setModify(true)}>{avatar ? 'Modify' : "select an avatar"}</h3>
    //             </Col>

    //         </Row>
    //     )
    // }

    return (
        <div className="avatar-container">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                {avatars.map((elt) =>
                    <Col key={elt} className="gutter-row  row" span={6} onClick={() => selectAvatar(elt)}>
                        <div className={`avatar-item ${elt === avatar && 'selected'}`}>
                            <img src={`avatars/${elt}.svg`} alt={elt} height={100} width={100} />
                        </div>
                    </Col>
                )}

            </Row>
        </div>
    );
};

