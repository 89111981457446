import { Alert, Form, Input, Radio, Space, message } from "antd";
import { BaseModal } from "./base.modal";
import { useBaseQuery } from "hooks/query.hook";
import { apiRoutes } from "api/data/routes";
import { useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { useTranslation } from "react-i18next";

export const PaylinksModal = ({ onClose }: any) => {

    const [type, setType] = useState('pay');
    const { t } = useTranslation('paylinks');

    const {
        data: paylinkData,
        error: paylinkError,
        isLoading: paylinkLoading,
        post: addPaylink,
    } = useBaseQuery(apiRoutes.ADMIN_PAYMENT_URL);


    const {
        data: productLinkData,
        error: productLinkError,
        isLoading: productLinkLoading,
        post: addProductlink,
    } = useBaseQuery(apiRoutes.ADMIN_PRODUCT_URL);

    const options: any = {
        pay: {
            label: t('payType'),
            data: paylinkData,
            onFinish: async (values: any) => {
                await addPaylink(values);
                message.success(t('createPayMessage'));
                onClose && onClose(true);
                setStatus('CLOSE');
            }
        },
        product: {
            label: t('productType'),
            data: productLinkData,
            onFinish: async (values: any) => {
                await addProductlink(values);
                message.success(t('createProductMessage'));
                onClose && onClose(true);
                setStatus('CLOSE');
            }
        }
    }
    const [form] = Form.useForm();

    const [status, setStatus] = useState<any>('OPEN');
    return (
        <BaseModal confirmLoading={type === 'pay' ? paylinkLoading : productLinkLoading} onOk={() => form.submit()} status={status} okTitle={t('createBtn')} title={t('createTitle')} subTitle={t('createSubtitle')}>
            <Form
                className="paylink-form"
                name="signup-form"
                form={form}
                requiredMark={false}
                onFinish={options[type].onFinish}
                layout="vertical"
            >

                <Radio.Group onChange={(value) => setType(value.target.value)} value={type}>
                    <Space direction="horizontal" className="mb-50">
                        {Object.keys(options).map((elt, index) => <Radio value={elt} key={index}>{options[elt].label}</Radio>)}
                    </Space>
                </Radio.Group>

                <Form.Item
                    name="fiatAmount"
                    label={t('amountLabel')}
                    rules={[{ required: true, message: "" }]}
                >
                    <Input size="large" />
                </Form.Item>

                <Form.Item
                    name="description"
                    label={t('descriptionLabel')}
                    rules={[{ required: true, message: "" }]}
                >
                    <TextArea
                        rows={4}
                        placeholder={t('descriptionPlaceHolder')}
                        maxLength={100}
                    />
                </Form.Item>
            </Form>
            {(paylinkError || productLinkError) && <Alert message={type === 'pay' ? paylinkError : productLinkError} type="error" className="text-error mt-10 " showIcon closable />}

        </BaseModal >
    );
}