
import React from "react";
import Router from "router";
import { Provider } from "react-redux"
import { store, persistor } from "reducers/auth/store";
import { AuthProvider } from "contexts/auth.context";
import { PersistGate } from 'redux-persist/integration/react';
import { ModalProvider, ModalRoot } from "contexts/modal.context";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from "components/error";

const App = () => {
  return (

    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthProvider>
            <ModalProvider>
              <ErrorBoundary
                FallbackComponent={ErrorComponent}
                onReset={() => {
                  // reset the state of your app here
                }}
                resetKeys={['someKey']}
              >
                <Router />
              </ErrorBoundary >
              <ModalRoot />
            </ModalProvider>
          </AuthProvider>
        </PersistGate>
      </I18nextProvider>
    </Provider>
  );
};

export default App;
